// @ts-nocheck
import jobsAPI from './api';
import TYPES from './types';

import { actions as locationActions } from 'services/location';
import { actions as repairerActions } from 'services/repairer';

export const getJobById = (id) => {
    return jobsAPI.getJobById(id);
};

export const loadLocations = (dispatch) => async function() {
    const locals = await locationActions.getLocations();
    if (dispatch && locals && locals.length) {
        dispatch({ type: 'load_locations', payload: locals });
    }
};

export const loadRepairers = (dispatch) => async function() {
    const repairers = await repairerActions.getRepairers();
    if (dispatch && repairers && repairers.length) {
        dispatch({ type: 'load_repairers', payload: repairers });
    }
};

export const setFilterParams = (dispatch) => async function(params) {
    dispatch({ type: 'set_filter_params', payload: params });
};

export const loadJobCount = (dispatch) => async function(errorContext) {
    try {
        const response = await jobsAPI.getJobCount();
        if (response?.data?.status === 200 && response.data.payload) {
            dispatch({ type: 'set_count', payload: response.data.payload });
        }
    } catch (error) {
        console.log(error);
        if (errorContext) {
            errorContext.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    }
};

export const getAllJobs = (dispatch) => async function(requestOptions, errorContext) {
    dispatch({ type: TYPES.LOADING, payload: true });
    try {
        const response = await jobsAPI.getJobs(requestOptions);

        if (response?.data?.status === 200 && response.data.payload.length) {
            dispatch({
                type: TYPES.GET_JOBS,
                payload: response.data.payload.filter(el => el).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            });
        } else if (response?.data?.status === 200 && !response.data.payload.length) {
            dispatch({ type: TYPES.GET_JOBS, payload: [] });
        }
        dispatch({ type: TYPES.LOADING, payload: false });
    } catch (error) {
        if (error?.response?.status && error.response.status === 404) {
            dispatch({ type: TYPES.GET_JOBS, payload: [] });
        }
        console.error('Error getting jobs:', error);
        if (errorContext) {
            errorContext.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    }
};

export const refreshJob = (dispatch) => async function(id, errorContext) {
    try {
        const response = await getJobById(id);
        if (response?.data?.status === 200 && response.data.payload) {
            dispatch({ type: TYPES.REFRESH_JOB, payload: response.data.payload });
        }
    } catch (error) {
        console.error('Error refreshing job:', error);
        if (errorContext) {
            errorContext.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    }
};