// React
import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as MediaRemove } from '../../../assets/images/remove_circle.svg';
import { ReactComponent as MediaUploadPDF } from '../../../assets/images/media_pdf.svg';
import { ReactComponent as MediaUploadPic } from '../../../assets/images/media_pic.svg';
import { useError } from 'contexts/ErrorContext';
// Styles
import {
    Button,
    Card,
    Grid,
    TextField,
    Typography,
    useTheme,
    IconButton,
    Box,
    Fade,
    Modal,
    Backdrop } from '@mui/material';
import { attachMediaToService, requestReworkService } from 'services/actions/service';
import axios from 'axios';

export const ServiceReworkModal = function ({
    open,
    setOpen,
    serviceID,
    serviceDetails,
    serviceDetailIDs,
    serviceMedia,
    serviceNotes,
    repairCatalog,
    DISPATCH,
}) {
    const theme = useTheme();
    const err = useError();

    const [mediaAnchor, setMediaAnchor] = useState([]);
    const [media, setMedia] = useState([]);
    const [note, setNote] = useState([]);

    const clickMediaAnchor = async function (e, i) {
        mediaAnchor[i].current.click();
    };

    const resetMediaAnchor = async function () {
        if (!serviceDetailIDs || !serviceDetailIDs.length) return;
        const t = serviceDetailIDs.split(',').map(i=> React.createRef());
        setMediaAnchor(t);
    };

    const MediaInputButton = function({ i, sID }) {
        return (
            <div>
                <Button
                    color='primary'
                    title={`btn-media-${i}`}
                    onClick={(e) => { clickMediaAnchor(e, i); }}
                    // disabled={mediaAnchor[i]}
                >
                    <MediaUploadPDF />
                    <MediaUploadPic />
                </Button>

                <input
                    ref={mediaAnchor[i]}
                    type='file'
                    style={{ display: 'none' }}
                    multiple={true}
                    accept='application/pdf,video/*,image/*'
                    onChange={e => {
                        renderMedia(e.target.files, sID);
                    }}
                />
            </div>
        );
    };

    MediaInputButton.propTypes = {
        i: PropTypes.number.isRequired,
        sID: PropTypes.string.isRequired,
    };

    const renderMedia = async function(files, id) {
        let data = [];
        // Build Promises of each File
        Array.from(files).forEach(async (element) => {
            let payload = new Promise((res, rej) => {
                const rendReader = new FileReader();
                rendReader.onload = () => {
                    const MEDIA_TYPES = { 'image': 'Image', 'video': 'Video', 'application':'PDF' };
                    // Final data shape of Media object
                    res({
                        'text': element.name || 'no caption',
                        'contentHeader': element.type,
                        'media_type': MEDIA_TYPES[element.type.split('/')[0]],
                        'localPath': rendReader.result,
                        'data': element,
                        'serviceID': id,
                    });
                };
                rendReader.readAsDataURL(element);
            });
            data.push(payload);
        });
        // Trigger Promises
        Promise.all(data).then((values) => {
            setMedia([...media, ...values]);
        });
    };

    const showThumbnails = function(sID) {
        return media.filter((el) => el.serviceID === sID).map((tn, i) => {
            // TODO check if iframe, video, or img tag is needed for display
            const formatThumbnail = function(med) {
                if (med.media_type === 'PDF') {
                    return <iframe width='70' height='70' src={med.localPath}></iframe>;
                } else if (med.media_type === 'Video') {
                    return <video width='70' height='70' controls>
                        <source src={med.localPath} type='video/mp4' />
                    </video>;
                }
                return <img
                    src={med.localPath}
                    width='70' height='70'
                    style={{}}
                />;
            };

            return (
                <div key={i} style={{ position: 'relative' }}>
                    <Card variant='outlined' sx={{ p: 0, m: .25 }}>
                        { formatThumbnail(tn) }
                    </Card>
                    <IconButton
                        onClick={() => {
                            const values = [...media.filter(el => el.text != tn.text)];
                            setMedia([...values]); // removing Media
                        }}
                        sx={{ '&:hover': {
                            backgroundColor: theme.palette.blue.main,
                        },
                        p: 0,
                        m: 0,
                        border: 0,
                        position: 'absolute',
                        bottom: '-4px',
                        right: '-4px',
                        backgroundColor: theme.palette.white }}>
                        <MediaRemove />
                    </IconButton>
                </div>
            );});
    };

    const renderMediaSubmission = function() {
        if (!serviceDetailIDs || !serviceDetailIDs.length) return;
        return serviceDetailIDs.split(',').map((el, i) => {
            let repairDetails = repairCatalog.filter(c => c.id == el)[0];
            return (
                <Card variant={'outlined'} key={i} sx={{ my: 1.5, width: '85%' }}>
                    <Grid container direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid item sx={{ borderBottom:'1px solid', borderBottomColor: theme.palette.grey[700], mb: 1 }}
                            display='flex' alignItems={'center'}
                            justifyContent={'space-between'} width={'88%'}>
                            <TextField
                                value={(serviceNotes && serviceNotes.length)
                                    ? serviceNotes.filter(n => n.serviceID == el).map(el => el.text || '')[0] : ''}
                                placeholder={'Type note here...'}
                                variant='standard'
                                id='set-note'
                                multiline={true}
                                inputProps={{ style: { fontSize: '16px', textTransform: 'none', marginLeft: '8px' } }}
                                sx={{ display: 'flex', width: '75%', fontSize: '16px', backgroundColor: 'rgba(255,255,255,.9)' }}
                                InputProps={{ disableUnderline: true }}
                                // @ts-ignore
                                onChange={(event) => {
                                    setNote([{
                                        'text': event.target.value,
                                        'media_type': 'Text',
                                        serviceID,
                                    }]);
                                } }
                            />
                            <MediaInputButton i={i} sID={el} />

                        </Grid>
                        <Grid item display='flex' width={'88%'} sx={{ overflow: 'auto' }}>
                            { (media && media.length > 0) && (
                                showThumbnails(el)
                            )}
                        </Grid>
                    </Grid>
                </Card>
            );
        });
    };

    const submitRework = async function(params) {
        try {
            const data = await requestReworkService({
                data: { 'media': [...note, ...media].map((el) => { return { 'text': el.text, 'media_type': el.media_type }; }) },
                serviceID,
            });
            if (data && data.data.status === 200 && data.data.payload) {
                if (media && media.length) {
                    let mediaUploads = [];
                    let servEl = data.data.payload;
                    if (servEl.uploadUrls && servEl.uploadUrls.length) {
                        servEl.uploadUrls.forEach((uurl) => {
                            const mFile = media.filter((el) => (el.media_type != 'Text' && el.media_type === uurl.type && el.text === uurl.text));
                            if (mFile && mFile.length) {
                                // UPLOAD TO AWS PROMISE
                                mediaUploads.push(
                                    axios.put(uurl.uploadUrl, mFile[0].data, { headers: { 'Content-Type': mFile[0].contentHeader } }),
                                );
                            }
                        });
                        // PATCH SERVICE WITH MEDIA
                        mediaUploads.push(
                            attachMediaToService({ serviceID, data: { media: servEl.uploadUrls.map(el => { return { id: el.id }; } ) } }),
                        );
                    }
                    let current = 1;
                    const loadingBarStatus = function(current, max) {
                        console.log(`Promise ${current} of ${max} completed/uploaded...`);
                    };
                    await Promise.all(mediaUploads.map((el) => el.then(() => {
                        if (current != mediaUploads.length) return loadingBarStatus(current++, mediaUploads.length);
                        return;
                    })));
                }
                // @ts-ignore
                err.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Rework Requested' } });
                DISPATCH();
                setOpen(false);
            }
        } catch (error) {
            // @ts-ignore
            console.error(error);
            err.DISPATCH({ type: 'API_ERROR', payload: error });
            DISPATCH();
            setOpen(false);
        }
    };

    useEffect(() => {
        if (serviceDetailIDs && (serviceDetailIDs.length != mediaAnchor.length)) {
            resetMediaAnchor();
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 300,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    backgroundColor: theme.palette.grey[30],
                    border: '0',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                    p: 1,
                }}>
                    <Grid justifyContent={'center'}>
                        <Grid item display={'flex'} justifyContent={'center'} my={1}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 20 }}>
                                Reason for rework
                            </Typography>
                        </Grid>
                        <Grid item display={'flex'} justifyContent={'center'} my={1}>
                            <Typography sx={{ color: theme.palette.grey[600], fontSize: 14 }}>
                                { serviceDetails }
                            </Typography>
                        </Grid>
                        <Grid item display={'flex'} justifyContent={'center'}>
                            { renderMediaSubmission() }
                        </Grid>
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Button
                                disabled={(!note.length)}
                                onClick={() => submitRework({})}
                                variant='contained'
                                sx={{
                                    backgroundColor: theme.palette.blue[700],
                                    color: theme.palette.white,
                                    p: 2,
                                    width: '85%',
                                    textTransform: 'none' }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
};

ServiceReworkModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    serviceID: PropTypes.string,
    serviceDetails: PropTypes.string,
    serviceDetailIDs: PropTypes.any, // array
    serviceMedia: PropTypes.any,
    repairCatalog: PropTypes.any,
    serviceNotes: PropTypes.any,
    DISPATCH: PropTypes.func,
};