import { STATUSES } from 'services/utils/enums';
import TYPES from './types';

export const INIT = function () {
    return INITIAL_STATE;
};

export const INITIAL_STATE = {
    searchTerm: '',
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    locations: [],
    selectedLocations: [],
    count: {
        'num_jobs': null,
        'num_jobs_containing_status': {
            'Open': 0,
            'Rework': 0,
            'Accepted': 0,
            'Declined': 0,
            'Invoiced': 0,
            'Cancelled': 0,
            'Completed': 0,
            'In Progress': 0,
            'Pending Approval': 0,
        },
    },
    repairers: [],
    selectedRepairers: [],
    statuses: STATUSES,
    selectedStatuses: [],
    supplements: [
        {
            id: 1,
            name: 'Supplement Approved',
        },
        {
            id: 2,
            name: 'Supplement Pending',
        },
        {
            id: 3,
            name: 'Rework Approved',
        },
        {
            id: 4,
            name: 'Rework Pending',
        },
    ],
    selectedServices: [],
    sort: [
        {
            id: 1,
            name: 'Newest',
        },
        {
            id: 2,
            name: 'Oldest',
        },
    ],
    filterParams: '',
    selectedSort: [],
    jobs:[],
    activeJob: 0,
    job: {},
    repairOrders:[],
    activeRepairOrder: 0,
    repairOrder: {},
    loaded: false,
};

export const JobReducer = function (state, action) {
    switch (action.type) {
        case TYPES.SET_FILTER_PARAMS: {
            let filterParams = (state.filterParams) ? state.filterParams.replace('?', '').split('&') : [];
            for (const key in action.payload) {
                filterParams = filterParams.filter(el => !el.includes(key));
                if (action.payload[key] && action.payload[key].length) {
                    filterParams.push(`${key}=${action.payload[key]}`);
                }
            }
            filterParams = filterParams.join('&');
            return { ...state, filterParams: `?${filterParams}` };
        }
        case TYPES.SET_SEARCH_TERM:
            return { ...state, searchTerm: action.payload };
        case TYPES.SET_START_DATE:
            return { ...state, startDate: action.payload };
        case TYPES.SET_END_DATE:
            return { ...state, startDate: action.payload };
        case TYPES.LOAD_LOCATIONS:
            return { ...state, locations: action.payload };
        case TYPES.LOAD_REPAIRERS:
            return { ...state, repairers: action.payload };
        case TYPES.SELECT_LOCATIONS:
            return { ...state, selectedLocations: action.payload };
        case TYPES.GET_JOBS:
            return {
                ...state,
                job: {},
                jobs: action.payload,
                loaded: true,
            };
        case TYPES.REFRESH_JOB: {
            let found = false;
            const newJobs = state.jobs.map(job => {
                if (job.id === action.payload.id) {
                    found = true;
                    return action.payload;
                }
                return { ...job };
            });

            if (!found) {
                newJobs.push(action.payload);
            }

            return {
                ...state,
                jobs: newJobs,
            };
        }
        case TYPES.GET_ROS:
            return { ...state, repairOrders: action.payload };
        case TYPES.SET_ACTIVE_CARD:
            return { ...state, activeJob: action.payload };
        case TYPES.GET_JOB:
            return { ...state, job: action.payload };
        case TYPES.SET_COUNT:
            return { ...state, count: action.payload };
        case TYPES.LOADING:
            return { ...state, loaded: !action.payload };
        case TYPES.RESET:
            return INIT();
        default:
            return { ...state };
    }
};

export default JobReducer;
