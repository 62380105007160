import { apiRodx } from '../../config';
import { useError } from 'contexts/ErrorContext';
import axios from 'axios';

export const attachMediaToService = async function(params) {
    const result = await apiRodx['post'](`service/${params.serviceID}/attach-media`, params.data);
    return result;
};

export const cancelService = async function(params) {
    const result = await apiRodx['patch'](`service/${params.serviceID}/cancel`);
    return result;
};

export const approveSupplementService = async function(params) {
    const result = await apiRodx['post'](`service/${params.serviceID}/accept-supplement`);
    return result;
};

export const declineSupplementService = async function(params) {
    const result = await apiRodx['post'](
        `/service/${params.serviceID}/reject-supplement`,
        { 'service_status': 'Declined', price: params.price },
    );
    return result;
};

export const requestReworkService = async function(params) {
    const result = await apiRodx['post'](`service/${params.serviceID}/request-rework`, params.data);
    return result;
};
