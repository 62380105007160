import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Box, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBarWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 212px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #CAD2D9;
  border-radius: 8px;
`;

const SearchInput = styled(InputBase)`
  flex: 1;
  padding-left: 8px;
  font-size: 12px;
  line-height: 14px;
  color: #343F46;
`;

const SearchIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const SearchBar = ( { dispatch, state, disabled } ) => {
    return (
        <SearchBarWrapper>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <SearchInput
                disabled={disabled}
                placeholder='Quick Find'
                value={state.searchTerm}
                onChange={({ target: { value: payload } }) => {
                    dispatch({ type: 'set_search', payload });
                }}
            />
        </SearchBarWrapper>
    );
};

SearchBar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

export default SearchBar;
