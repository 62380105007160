export default {
    INIT: 'INIT',
    SET_LOCATIONS: 'SET_LOCATIONS',
    SET_LOCATION: 'SET_LOCATION',
    SET_ACTIVE_LOCATION: 'SET_ACTIVE_LOCATION',
    EDIT_LOCATION_PROP: 'EDIT_LOCATION_PROP',
    EDIT_LOCATION_CONTACT_PROP: 'EDIT_LOCATION_CONTACT_PROP',
    EDIT_LOCATION_ADDRESS: 'EDIT_LOCATION_ADDRESS',
    CHANGE_LOCATION_VALIDATION: 'CHANGE_LOCATION_VALIDATION',
    UPDATE_LOCATION: 'UPDATE_LOCATION',
    CLEAR_NEW_LOCATION: 'CLEAR_NEW_LOCATION',
    EDIT_NEW_LOCATION_PROP: 'EDIT_NEW_LOCATION_PROP',
    EDIT_NEW_LOCATION_CONTACT_PROP: 'EDIT_NEW_LOCATION_CONTACT_PROP',
    EDIT_NEW_LOCATION_ADDRESS: 'EDIT_NEW_LOCATION_ADDRESS',
    CHANGE_NEW_LOCATION_VALIDATION: 'CHANGE_NEW_LOCATION_VALIDATION',
    CREATE_LOCATION: 'CREATE_LOCATION',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    UPDATE_SEARCH_TERM: 'UPDATE_SEARCH_TERM',
};
