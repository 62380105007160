import React, { useEffect, useState } from 'react';

import { Box, styled, Grid, Typography, useTheme, Button } from '@mui/material';
import { Mail } from '@mui/icons-material';

import { useError } from 'contexts/ErrorContext';
import { useLocations, actions as LocationActions } from 'services/location';
import { useCompany, actions as CompanyActions } from 'services/company';

import { useAuth } from 'contexts/AuthContext';
import CompanyInfo from './content/Company';
import Repairers from './content/Repairers';
import Users from './content/Users';
import Settings from './content/Settings';
import Developers from './content/Developers';
import AssignLocationModal from './content/Repairers/Content/Modals/AssignLocationModal';

import './styles.css';
const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: 0,
    width: '100%',
}));

const NewButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    borderRadius: '8px',
    background: theme.palette.blue.main,
    color: 'white',
    '& .MuiButton-startIcon': {
        color: theme.palette.blue[300],
    },
}));

const AdminScreen = () => {
    const theme = useTheme();
    const errorContext = useError();

    // Tabs
    const pills = ['Company Info', 'Repairers', 'Users', 'Settings', 'Developers'];
    const [currentTab, setCurrentTab] = useState('Developers');

    const handlePillClick = function (pill) {
        setCurrentTab(pill);
    };

    // Invite repairer modal
    const [inviteRepairerModalOpen, setInviteRepairerModalOpen] = useState(false);
    const [inviteRepairerModalSuccess, setInviteRepairerModalSuccess] = useState(false);

    // Locations
    const locationContext = useLocations();
    const [locationsLoaded, setLocationsLoaded] = useState(false);
    const reloadLocations = (locContext, errContext, onLocationsLoaded) => {
        if (!locationsLoaded) {
            LocationActions.refreshLocations({}, locContext, errContext, onLocationsLoaded);
        }
    };

    useEffect(
        () => reloadLocations(locationContext, errorContext, () => setLocationsLoaded(true)),
        [locationsLoaded],
    );

    // Company
    const companyContext = useCompany();
    const [companyLoaded, setCompanyLoaded] = useState(false);
    const reloadCompany = (compContext, errContext, onCompanyLoaded) => {
        if (!companyLoaded) {
            CompanyActions.refreshCompany(compContext, errContext, onCompanyLoaded);
        }
    };

    useEffect(
        () => reloadCompany(companyContext, errorContext, () => setCompanyLoaded(true)),
        [companyLoaded],
    );

    // Tab switch effects
    useEffect(() => {
        if (currentTab === 'Repairers') {
            reloadLocations(locationContext, errorContext, () => setLocationsLoaded(true));
        }
        if (currentTab === 'Company Info') {
            reloadCompany(companyContext, errorContext, () => setCompanyLoaded(true));
        }
    }, [currentTab]);

    const pillBar = function () {
        return pills.map((pill, i) => {
            return (<Grid item key={i}>
                <Button
                    onClick={() => handlePillClick(pill)}
                    sx={{ p: '3px',
                        m: '4px',
                        fontSize: '16px',
                        textTransform: 'none',
                        color: (pill === currentTab) ? theme.palette.blue[500] : theme.palette.grey[500],
                    }}
                    className={(pill === currentTab) ? 'active': ''}
                > { pill } </Button>
            </Grid>);
        });
    };

    const newButton = function() {
        switch (currentTab) {
            case 'Repairers':
                return (
                    <NewButton
                        variant='contained'
                        onClick={() => setInviteRepairerModalOpen(true)}
                        startIcon={<Mail />}
                    >
                        Invite Repairer
                    </NewButton>
                );
            case 'Users':
                return (
                    <NewButton
                        variant='contained'
                        // onClick={() => navigate('/new_user')}
                        startIcon={<Mail />}
                    >
                          Invite User
                    </NewButton>
                );
            default:
                return;
        }
    };

    const showContent = function () {
        switch (currentTab) {
            case 'Company Info':
                return (<CompanyInfo />);
            case 'Repairers':
                return (<Repairers />);
            case 'Users':
                return (<Users />);
            case 'Settings':
                return (<Settings />);
            case 'Developers':
                return (<Developers />);
            default:
                return (<CompanyInfo />);
        }
    };

    const modalElem = !inviteRepairerModalOpen ? null : (
        <AssignLocationModal
            inviteRepairer
            open={inviteRepairerModalOpen}
            locations={(locationContext.state?.locations || [])}
            onClose={() => { setInviteRepairerModalOpen(false); setInviteRepairerModalSuccess(false); } }
            onSubmit={() => {
                // MTODO: send invitation
                setInviteRepairerModalSuccess(true);
            }}
            success={inviteRepairerModalSuccess}
        />
    );

    return (
        <Grid container display={'flex'} direction={'column'}>
            {modalElem}
            <Typography variant={'h1'} m={0} mb={1} p={0} pb={1}> Administration </Typography>
            <Grid item display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'}> { pillBar() } </Box>
                { newButton() }
            </Grid>
            <Grid item container display={'flex'} my={4}>
                <ContentContainer>
                    { showContent() }
                </ContentContainer>
            </Grid>
        </Grid>
    );
};

export default AdminScreen;
