import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Paper, useTheme } from '@mui/material';

import { STATUS_BAR_CLASS, STATUS_BAR_DISPLAY } from 'services/utils/enums';
import { useJobs } from 'services/job';
import { useError } from 'contexts/ErrorContext';

import JobCard from './JobCard';

const JobList = () => {
    const theme = useTheme();
    const err = useError();
    const jobsContext = useJobs();

    const browserLocation = useLocation();
    const [shouldScrollToJobCard, setShouldScrollToJobCard] = React.useState(true);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (!jobsContext.state.activeJob) {
            if (jobsContext.state?.job?.id) {
                setActiveCard(jobsContext.state.job.id);
            } else if (jobsContext.state.jobs.length) {
                setActiveCard(jobsContext.state.jobs[0].id);
            }
        } else {
            // Disable auto scroll to card if job is not selected from a deep link (i.e. user clicks on a card)
            setShouldScrollToJobCard(false);
        }
    }, [jobsContext.state.job, jobsContext.state.activeJob]);

    // Auto scroll to card if job is selected from a deep link
    useEffect(() => {
        const params = browserLocation.search ? new URLSearchParams(browserLocation.search) : null;
        const jobIdFromURL = params ? params.get('job') : null;

        if (jobIdFromURL) {
            setShouldScrollToJobCard(true);
            setActiveCard(jobIdFromURL);
        }
    }, [browserLocation]);

    const formatJob = function (job) {
        // let { created_at, repairer_company, price, location, id, vehicle } = job;
        const getTimestamp = (createdAt) => {
            return new Date(createdAt).toDateString();
            // const hoursSince = Math.abs(new Date() - new Date(createdAt)) / 3600000;
            // if (hoursSince <= .05) {
            //     return 'New';
            // }
            // if (hoursSince <= 1) {
            //     return `${Math.floor(hoursSince*60)}mins ago`;
            // }
            // if (hoursSince > 1 && hoursSince <= 24) {
            //     return `${Math.floor(hoursSince)}hrs ago`;
            // }
            // if (hoursSince > 24 && hoursSince <= (24*30)) {
            //     return `${Math.floor(hoursSince/24)}days ago`;
            // }
            // if (hoursSince > (24*30) && hoursSince <= (24*365)) {
            //     return `${Math.floor(hoursSince/24/30)}months ago`;
            // }
            // return `${Math.floor(hoursSince/24/30/365)}years ago`;
        };

        const getJobStatus = function (services) {
            // @ts-ignore
            const s = [...new Set(services.map(el => STATUS_BAR_DISPLAY[el.service_status]))].map((el) => {
                return {
                    status: el,
                    statusStyle: STATUS_BAR_CLASS[el],
                };
            });
            return s;
        };
        if (job) {
            return {
                timestamp: getTimestamp(job.created_at),
                jobStatuses: getJobStatus(job.services),
                color: job.vehicle.color || '',
                location: job.location.nickname,
                // @ts-ignore
                repairerList: [...new Set(job.services.map(el => el.repair_company.name))].join(', '),
                // @ts-ignore
                serviceList: [...new Set(job.services.map(el => el.service_details.service_type))].join(', '),
                numServices: job.services.length, // number of services on the job
                total: job.services.reduce((sum, part) => sum += part.price, 0),
                currency: (job.currency) ? job.currency.abbreviation : 'USD', // ISO currency code
                stockRO: job.vehicle.stock_number || '',
                vin: job.vehicle.vin || '',
                year: job.vehicle.year || '',
                make: job.vehicle.make || '',
                model: job.vehicle.model || '',
                id: job.id,
            };
        }
    };

    const setActiveCard = async function (id) {
        console.log('setActiveCard', id);
        jobsContext.DISPATCH({ type: 'set_active_card', payload: id });
        try {
            const data = await jobsContext.actions.getJobById(id);
            if (data && data.data.status === 200 && data.data.payload) {
                jobsContext.DISPATCH({ type: 'get_job', payload: data.data.payload });
            }
        } catch (error) {
            err.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    };

    const showJobCards = () => {
        return jobsContext.state.jobs.map((job, i) => {
            return (
                <JobCard
                    data={ formatJob(job) }
                    key={i}
                    job={job}
                    state={jobsContext.state}
                    setActiveCard={setActiveCard}
                    shouldScrollToJobCard={shouldScrollToJobCard}
                />
            );
        });
    };

    return (
        <Paper sx={{ backgroundColor: theme.palette.background.paper, pb: 4 }}>
            { showJobCards() }
        </Paper>

    );
};

JobList.propTypes = {
};

export default JobList;