import React, { useEffect, useState, useCallback } from 'react';

import debounce from 'lodash.debounce';

import { Box, styled, Grid, useTheme, Divider } from '@mui/material';
import { AddLocationAlt } from '@mui/icons-material';

import { ReactComponent as RepairerIcon } from 'assets/images/repairer.svg';
import { ReactComponent as StatusInfoIcon } from 'assets/images/status_info_icon.svg';

import { useError } from 'contexts/ErrorContext';
import { useLocations, actions, TYPES as LOCATION_TYPES } from 'services/location';

import TitlePanel from 'components/Panels/TitlePanel';
import SearchAndFilterPanel from 'components/Panels/SearchAndFilterPanel';
import ListAndDetailsPanel from 'components/Panels/ListAndDetailsPanel';

import LocationList from './content/LocationList';
import LocationDetails from './content/LocationDetails.jsx';
import LocationModal from './content/NewLocationModal';

const filtersDefinitions = {
    repairers: {
        title:'Repairers',
        options: [],
        IconComponent: RepairerIcon,
    },
    status: {
        title: 'Status',
        options: [],
        IconComponent: StatusInfoIcon,
    },
    invoice: {
        title: 'Invoice Type',
        options: [],
        IconComponent: StatusInfoIcon,
    },
};

const generateFilterDefinitions = (filterOptions) => {
    return Object.entries(filterOptions).reduce((acc, [id, options]) => {
        if (filtersDefinitions[id]) {
            acc[id] = { ...filtersDefinitions[id], options };
        }
        return acc;
    }, {});
};

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0),
    width: '100%',
}));

const LocationsScreen = () => {
    const theme = useTheme();
    const locationContext = useLocations();
    const errorContext = useError();
    // MTODO: move to reducer and change on every location API request
    const [locationsLoaded, setLocationsLoaded] = useState(false);
    const [timeReload, resetTimeReload] = useState(false);
    const [addLocationPopupOpen, setAddLocationPopupOpen] = useState(false);

    const locationFilters = React.useMemo(() => generateFilterDefinitions(locationContext.state.filters), [locationContext.state.filters]);

    const onLocationsLoaded = (locations) => {
        setLocationsLoaded(true);
        resetTimeReload(false);
    };

    const titleActions = [
        {
            title: 'Add Location',
            onClick: () => setAddLocationPopupOpen(true),
            iconComponent: AddLocationAlt,
        },
    ];

    const handleLocationFilterChange = (propName, selectedOptions) => {
        locationContext.DISPATCH({ type: LOCATION_TYPES.UPDATE_FILTERS, payload: { propName, selectedOptions } });
    };

    const handleLocationSearchTermChange = (searchTerm) => {
        locationContext.DISPATCH({ type: LOCATION_TYPES.UPDATE_SEARCH_TERM, payload: { searchTerm } });
    };

    const reloadLocations = (locContext, errContext, onLocationsLoaded) => {
        if (resetTimeReload || !locationsLoaded) {
            if (
                resetTimeReload ||
                (!locContext.state.locations || (locContext.state.locations && !locContext.state.locations.length))
            ) {
                actions.refreshLocations(
                    { filter: locContext.state.filters, textQuery: locContext.state.searchTerm },
                    locContext,
                    errContext,
                    onLocationsLoaded,
                );
            }
        }
        // TODO Finish and enable auto repull
        // if (!timeReload) {
        //     setTimeout(() => {
        //         resetTimeReload(true);
        //     }, 1200000); // 20 minute reload data
        // }
    };

    const debouncedReloadLocations = useCallback(debounce(reloadLocations, 500), []);

    // To disable locations reload on filter or search change (until API support is added), remove them from dependecy list
    useEffect(
        () => debouncedReloadLocations(locationContext, errorContext, onLocationsLoaded),
        [locationsLoaded, timeReload, locationContext.state.filters, locationContext.state.searchTerm],
    );

    const newLocationModalElem = addLocationPopupOpen ? (
        <LocationModal open={addLocationPopupOpen} onClose={() => {
            setAddLocationPopupOpen(false);
            locationContext.DISPATCH({ type: LOCATION_TYPES.CLEAR_NEW_LOCATION });
        }} />
    ) : null;

    return (
        <Grid container display={'flex'} direction={'column'}>
            {newLocationModalElem}
            <TitlePanel title='Locations' actions={titleActions} />
            <Grid container display={'flex'}>
                <ContentContainer>
                    <SearchAndFilterPanel
                        filters={locationFilters}
                        onFilterChange={handleLocationFilterChange}
                        searchTerm={locationContext.state.searchTerm ?? ''}
                        onSearchTermChange={handleLocationSearchTermChange}
                        searchOptions={['Lemur Lot', 'The Jags']}
                    />
                    <Divider sx={{ color: theme.palette.grey[50], width: '100%' }} />
                    <ListAndDetailsPanel
                        list={locationContext?.state?.locations || []}
                        singularTitle='Location'
                        pluralTitle='Locations'
                        listElement={LocationList}
                        detailElement={LocationDetails}
                        loaded={locationsLoaded}
                    />
                </ContentContainer>
            </Grid>
        </Grid>
    );
};

export default LocationsScreen;
