
import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import FilterButton from './FilterButton';
import SearchButton from './SearchButton';

export default function SearchAndFilterPanel({ filters, onFilterChange, searchTerm, onSearchTermChange, searchOptions = [] }) {
    const searchElem = (
        <SearchButton
            value={searchTerm}
            onChange={onSearchTermChange}
            options={searchOptions}
        />
    );

    const filterElems = Object.entries(filters).map(([id, { title, options, IconComponent }]) => (
        <Grid item key={title}>
            <FilterButton
                title={title}
                options={options}
                IconComponent={IconComponent}
                onSelect={(selectedFilters) => onFilterChange(id, selectedFilters)}
            />
        </Grid>
    ));

    return (
        <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item display={'flex'} p={1} mx={1} xs={2}>
                { searchElem }
            </Grid>
            <Grid item container direction='row' justifyContent='end' p={1} mx={1} xs={10} spacing={1}>
                { filterElems }
            </Grid>
        </Grid>
    );
}

SearchAndFilterPanel.propTypes = {
    filters: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    searchOptions: PropTypes.arrayOf(PropTypes.string),
};
