import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useTheme, Typography, Stack, Box, ButtonBase, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import { useJobs } from 'services/job';
import Loading from 'components/Loading';
import IconContainer from 'components/Icons/IconContainer';

import { StyledTitle, NotificationCardStyles } from './styles';

const NotificationDetails = ({ notification, onClose }) => {
    const theme = useTheme();
    const jobsContext = useJobs();
    const navigate = useNavigate();
    const iconConfig = NotificationCardStyles(theme)[notification.title] ?? { };

    const jobId = notification?.['job_id'];
    const notificationOnClickURL = `jobs${jobId ? `?job=${jobId}` : ''}`;

    const [job, setJob] = React.useState(null);

    React.useEffect(() => {
        if (notification?.['job_id']) {
            jobsContext.actions.refreshJob(jobId);
        }
    }, [notification]);

    React.useEffect(() => {
        if (jobsContext.state.jobs) {
            const job = jobsContext.state.jobs.find((job) => job.id === jobId);
            setJob(job);
        }
    }, [jobsContext.state.jobs]);

    let carDetails = ''; // Year Make Model, Color
    let vinDetails = ''; // VIN: 123456789101122  -  Stock: 789123

    if (job?.vehicle) {
        const { year, make, model, color, vin, stock_number: stockNumber } = job.vehicle;

        carDetails = [
            year,
            make,
            model,
        ].filter((part) => part).join(' ');

        if (color) {
            carDetails += `${carDetails.length ? ', ' : ''}${color}`;
        }

        vinDetails = [
            vin ? `VIN: ${vin.toUpperCase()}` : '',
            stockNumber ? `Stock: ${stockNumber}` : '',
        ].filter((part) => part).join(' - ');
    }

    const contentElem = !job ? (
        <Loading loading fullScreen={false} width={150} height={150} />
    ) : (
        <>
            <Box sx={{
                display: 'flex',
                my: 1,
                ml: 0,
                py: 1.5,
                px: 1,
                background: theme.palette.grey[30],
                borderRadius: '8px',
            }}>
                <Stack direction='column' spacing={0} style={{ marginLeft: '10px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IconContainer
                            icon={iconConfig.icon ?? WorkOutlineIcon}
                            backgroundCircle
                            sx={{ background: iconConfig.color ?? 'red', color: 'white' }}
                            iconProps={{ style: { color: 'white', width: '10px', height: '10px' } }}
                            width='18px'
                            height='18px'
                        />
                        <Typography ml={1} sx={{ marginTop: 0, lineHeight: '25px', fontSize: '16px', fontWeight: 500 }}>
                            {notification.title}
                        </Typography>
                    </Box>
                    <Box my={1}>
                        <Typography sx={{ fontSize: 16, color: theme.palette.grey[500], fontWeight: 400 }}>
                            {notification.body || ''}
                        </Typography>
                    </Box>
                    <Typography sx={{ fontSize: 12, color: theme.palette.grey[800], fontWeight: 400 }}>
                        {vinDetails}
                    </Typography>
                    <Typography sx={{ fontSize: 12, color: theme.palette.grey[800], fontWeight: 400 }}>
                        {carDetails}
                    </Typography>
                    <Box my={1}>
                        <Typography sx={{ fontSize: 12, color: 'blue', fontWeight: 400 }}>
                            <Link
                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                href={notificationOnClickURL}
                                onClick={(event) => {
                                    navigate(notificationOnClickURL);
                                    event.preventDefault();
                                }}
                            >
                                View Job
                            </Link>
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            {/* TODO: EXTRACT DATE FROM NOTIFICATION WHEN API SUPPORTS IT */}
            <Typography my={0} mr={0.5} sx={{ fontSize: 12, color: theme.palette.grey[300], textAlign: 'right' }}>
                July 11 - 3:30 PM
            </Typography>
        </>
    );

    return (
        <Stack direction='column' m={1} minWidth='300px'>
            <Stack direction='row' spacing={1} my={1} alignItems='center'>
                <ButtonBase
                    onClick={onClose}
                    sx={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        ':hover': {
                            background: theme.palette.grey[40],
                        },
                    }}
                >
                    <IconContainer
                        icon={ArrowBackIcon}
                        style={{ alignSelf: 'center', height: '20px' }}
                        iconProps={{ style: { color: theme.palette.grey[700] } }}
                    />
                </ButtonBase>
                <StyledTitle variant='h2'>
                    {notification.title}
                </StyledTitle>
            </Stack>
            {contentElem}
        </Stack>
    );
};

NotificationDetails.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        body: PropTypes.string,
        read: PropTypes.bool.isRequired,
        'job_id': PropTypes.string,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default NotificationDetails;
