import { apiRodx } from '../../config';

export const getJobs = async function(params) {
    let config = params;
    const result = await apiRodx['get'](`jobs${config.requestParams}`);
    return result;
};

export const getJobCount = async function() {
    const result = await apiRodx['get']('job/count');
    return result;
};

export const getJobById = async function(id) {
    const result = await apiRodx['get'](`job/${id}`);
    return result;
};

export const createJob = async function(params) {
    const result = await apiRodx['post']('job', params.data);
    return result;
};

export const updateJob = async function(params) {
    const result = await apiRodx['patch'](`job/${params.id}`, params.data);
    return result;
};

export default {
    getJobs,
    getJobCount,
    getJobById,
    createJob,
    updateJob,
};
