import axios from 'axios';

const RODX_API_HOST = process.env.REACT_APP_host;
const PORTAL_API_HOST = process.env.REACT_APP_PORTAL_API_HOST || 'https://ubnlpz1mp8.execute-api.us-east-2.amazonaws.com';

export const firebaseAppConfig = {
    apiKey: process.env.REACT_APP_apiKey || 'AIzaSyBxk5DtlmmHihNIqudDqFC44mHOSkTKEH4',
    authDomain: process.env.REACT_APP_authDomain || 'repair-ondemand.firebaseapp.com',
    projectId: process.env.REACT_APP_projectId || 'repair-ondemand',
    storageBucket: process.env.REACT_APP_storageBucket || 'repair-ondemand.appspot.com',
    messagingSenderId: process.env.REACT_APP_messagingSenderId || '860517169068',
    appId: process.env.REACT_APP_appId || '1:860517169068:web:3180a6b4234f999ba9ac09',
    measurementId: process.env.REACT_APP_measurementId || 'G-D1FT041W4L',
};

export const apiRodx = axios.create({
    baseURL: RODX_API_HOST,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'common': {
            // default authorization to prevent first time load errors before selecting auth from admin screen
            'Authorization': '1824346de77f72ab9ded64321f6736735252cc6b5bd3dacdbd823c16f333fc2a',
        },
    },
});

export const apiPortal = axios.create({
    baseURL: PORTAL_API_HOST,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});
