import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import Modal from 'components/Modal';

const MediaWithPopup = ({ url, title, type = 'Image', opensToModal = true, width = '50px', height ='50px' }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const aProps = {
        opensToModal : {
            onClick: () => {
                setModalOpen(true);
                return false;
            },
        },
        openToNewTab: {
            target:'_blank',
            rel:'noreferrer',
            href: url,
        },
    };

    const thumbnailElem = type === 'Image' ? (
        <img width={width} height={height} src={url} />
    ) : (
        <PlayCircleIcon fontSize='large' sx={{ width, height }} />
    );

    const mediaElem = type === 'Image' ? (
        <img
            style={{
                width: title ? '80%' : '80%',
                maxHeight: '900px',
                objectFit: 'contain',
                backgroundSize: 'contain',
            }}
            src={url}
        />
    ) : (
        <video
            style={{
                width: title ? '80%' : '80%',
                maxHeight: '900px',
            }}
            src={url}
            controls
        />
    );

    const modalElem = !modalOpen ? null : (
        <Modal
            open={modalOpen}
            title={(title ?? '').toLocaleUpperCase()}
            onClose={() => setModalOpen(false)}
            fullScreen
        >
            <Box width='100%' display='flex' justifyContent='center' pt={title ? 0 : 3}>
                <Box
                    width='90%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    p={3}
                    pt={title ? 0 : 3}
                >
                    {mediaElem}
                </Box>
            </Box>
        </Modal>
    );

    return (
        <>
            {modalElem}
            <a {...(opensToModal ? aProps.opensToModal : aProps.openToNewTab)} style={{ cursor: 'pointer', display: 'contents' }}>
                {thumbnailElem}
            </a>
        </>
    );
};

MediaWithPopup.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.oneOf(['Image', 'Video']),
    opensToModal: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default MediaWithPopup;
