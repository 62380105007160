// @ts-nocheck
import companyAPI from './api';
import TYPES from './types';
import { validateRequired, validatePhone, validateAddress, validateEmail, validateObject } from '../utils/validation';

const COMPANY_PROP_VALIDATION = [
    [validateRequired, 'company_name', 'Company Name'],
    [[validateRequired, validateAddress], 'mailing_address', 'Mailing Address', (company) => company.mailing_address],
    [[validateRequired, validateAddress], 'billing_address', 'Mailing Address', (company) => company.billing_address],
    [validatePhone, 'contact_info.phone_number', 'Phone Number'],
    [validateEmail, 'contact_info.email', 'Email'],
];

export const getCompany = async (successCB, errorCB) => {
    const handleError = (error) => {
        if (errorCB && typeof errorCB === 'function') {
            errorCB(error);
        } else {
            throw new Error(`Error loading company: ${JSON.stringify(error)}`);
        }
    };

    try {
        const response = await companyAPI.getCompany();
        if (response && response.data.status === 200 && response.data.payload) {
            if (successCB && typeof successCB === 'function') {
                successCB(response.data.payload);
            }
            return response.data.payload;
        }
        handleError({ status: response.data.status, type: 'API_ERROR', msg: response?.message ?? response });
    } catch (error) {
        handleError({ type: 'API_ERROR', msg: error });
    }
};

export const refreshCompany = async (companyContext, errContext, successCB, errorCB) => {
    const onCompanyLoadError = ({ status, type, msg }) => {
        errContext.DISPATCH({
            type,
            payload: { status, alert: msg },
        });
        if (errorCB && typeof errorCB === 'function') {
            errorCB({ status, type, msg });
        }
    };

    const onCompanyLoaded = (company) => {
        companyContext.DISPATCH({ type: TYPES.SET_COMPANY, payload: company });

        if (successCB && typeof successCB === 'function') {
            successCB(company);
        }
    };

    await getCompany(onCompanyLoaded, onCompanyLoadError);
};

export const updateCompany = async (company, companyContext, errContext) => {
    // TODO: change prop names to match current API implementation
    // TOREMOVE: remove after API is updated so that request props are the same as response props
    company.name = company.company_name;

    try {
        const res = await companyAPI.updateCompany({ data: company });
        if (res?.data?.status === 200 && res?.data?.payload) {
            companyContext.DISPATCH({ type: TYPES.UPDATE_COMPANY, payload: res.data.payload });
            errContext.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Company updated!', status: res.data.status } });
        }
    } catch (error) {
        errContext.DISPATCH({ type: 'API_ERROR', payload: error });
    }
};

export const validateCompany = (companyContext) => {
    const company = companyContext.state.company;

    const validationMsgs = validateObject(company, COMPANY_PROP_VALIDATION);

    companyContext.DISPATCH({
        type: TYPES.CHANGE_VALIDATION,
        payload: validationMsgs,
    });
};
