import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Paper, Typography, useTheme, Button, Divider, IconButton, Card, MenuItem, Menu, styled } from '@mui/material';
import { ChevronRight, MoreVert as MoreVertIcon } from '@mui/icons-material';

import { ReactComponent as RepairIcon } from 'assets/images/repair_icon.svg';
import { ReactComponent as ServiceIcon } from 'assets/images/service_icon.svg';

import { cancelService, approveSupplementService, declineSupplementService } from 'services/actions/service';
import { STATUS_BAR_CLASS, STATUS_BAR_DISPLAY } from 'services/utils/enums';
import { useError } from 'contexts/ErrorContext';

import ServiceHistory from './Service/History/ServiceHistoryModal';
import { ServiceReworkModal } from './ServiceReworkModal';

const JobDetails = ({ dispatch, state }) => {
    console.log('job:', state);

    const theme = useTheme();
    const err = useError();

    const ApproveButton = styled(Button)(({ theme }) => ({
        textTransform: 'uppercase',
        borderRadius: theme.spacing(1),
        padding: '2px 8px',
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        background: theme.palette.blue[40],
        color: theme.palette.blue.main,
        '&: hover': {
            background: theme.palette.blue[40],
            color: theme.palette.blue.main,
        },
    }));
    const DeclineButton = styled(Button)(({ theme }) => ({
        textTransform: 'uppercase',
        borderRadius: theme.spacing(1),
        padding: '2px 8px',
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        background: theme.palette.grey[40],
        color: theme.palette.red.main,
        '&: hover': {
            background: theme.palette.grey[40],
            color: theme.palette.red.main,
        },
    }));

    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [currentOpenMenu, setcurrentOpenMenu] = useState(0);
    const [reworkModalOpen, setRWModalOpen] = useState(false);

    const handleClickMenu = ((current) => {
        setAnchorElMenu(current);
    });

    const handleCloseMenu = (() => {
        setAnchorElMenu(null);
        setcurrentOpenMenu(0);
    });

    const handleCancelMenuItem = async function(option) {
        try {
            await Promise.all(option.payload.map(el => cancelService(el)));
            await dispatch({ type: 'set_active_card', payload: 0 });
            handleCloseMenu();
        } catch (error) {
            console.log(error);
            // @ts-ignore
            err.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    };

    const handleReworkMenuItem = async function(option) {
        setRWModalOpen(true);
        // handleCloseMenu();
    };

    const handleCancelButton = async function(option) {
        try {
            await Promise.all(option.payload.map(el => cancelService(el)));
            await dispatch({ type: 'set_active_card', payload: 0 }); // Reloads Page
        } catch (error) {
            console.log(error);
            // @ts-ignore
            err.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    };

    const capitalize = (str) =>
        str.toLowerCase().replace(/\b\w/g, (match) =>
            match.replace(/\w/, (m) => m.toUpperCase()));

    const getYYMC = function() {
        let yymc = [];
        if (state.job.vehicle) {
            if (state.job.vehicle.year || state.job.vehicle.make || state.job.vehicle.model) yymc.push(`${state.job.vehicle.year || ''} ${capitalize(state.job.vehicle.make) || ''} ${capitalize(state.job.vehicle.model) || ''}`);
            if (state.job.vehicle.color) yymc.push(capitalize(state.job.vehicle.color));
        }
        return yymc.join(', ');
    };

    const getDateCreated = function() {
        return `Created on: ${new Date(state.job.created_at).toDateString()}`;
    };

    const getStockRO = function() {
        if (state.job.vehicle) {
            return `Stock/RO: ${state.job.vehicle.stock_number || ''}`;
        }
    };

    const getVIN = function() {
        if (state.job.vehicle) {
            return `VIN: ${state.job.vehicle.vin || ''}`;
        }
    };

    const getPO = function() {
        return `PO#: ${state.job.po_number || ''}`;
    };

    const getLocation = function() {
        return state.job.location.nickname || 'Location nickname';
    };

    const [viewingHistory, setViewingHistory] = useState([]);
    const [viewing, setViewing] = useState(0);

    const resetViewing = async function () {
        const v = state.job.services.map(() => false);
        await setViewingHistory(v);
        await setViewing(0);
    };

    const handleHistoryView = async function (service, i) {
        let t = viewingHistory;
        t[i] = true;
        setViewing(service.id);
        await setViewingHistory(t);
    };

    const handleHistoryViewClose = async function() {
        resetViewing();
    };

    const handleSupplement = async function(decision, service, price) {
        try {
            if (decision == 'approve') {
                await approveSupplementService({ serviceID: service });
                await setTimeout(() => {
                    dispatch({ type: 'set_active_card', payload: 0 });
                }, 250);
            } else {
                await declineSupplementService({ serviceID: service, price: price });
                await setTimeout(() => {
                    dispatch({ type: 'set_active_card', payload: 0 });
                }, 250);
            }
        } catch (error) {
            console.log(error);
            // @ts-ignore
            err.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    };

    const quickOptionsEnums = {
        'CANCELLABLE': ['OPEN'],
        'REWORKABLE': ['COMPLETED', 'CLOSED', 'INVOICED'],
        'REASSIGNABLE': ['OPEN', 'CANCELLED'],
    };

    const getRepairerCards = function() {
        // @ts-ignore
        const repList = [...new Set(state.job.services.map(el => el.repair_company.name))].sort();
        const repServ = repList.map((rep, key) => {
            let serviceList = state.job.services.filter(el => el.repair_company.name === rep);
            let total = serviceList.reduce((sum, part) => sum += part.price, 0);
            let closeOpts = [];
            let reworkOpts = [];
            closeOpts.push({
                label: `Cancel all possible ${rep} services`,
                payload: serviceList.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).map(el => ({ serviceID: el.id })),
            });
            closeOpts.push(...serviceList.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).map(el => {
                return {
                    label: `Cancel ${el.service_details.service_type} ${(el.service_details.position) ? el.service_details.position.replace('N/A', '') : ''} ${(el.service_details.part) ? el.service_details.part.replace('N/A', '') : ''}`,
                    payload: [{ serviceID: el.id }],
                };
            }));
            reworkOpts.push(...serviceList.filter(el => quickOptionsEnums['REWORKABLE'].includes(el.service_status.toUpperCase())).map(el => {
                return {
                    label: `Request rework on ${el.service_details.service_type} ${(el.service_details.position) ? el.service_details.position.replace('N/A', '') : ''} ${(el.service_details.part) ? el.service_details.part.replace('N/A', '') : ''}`,
                    serviceID: el.id,
                    serviceDetailIDs: el.service_details.id,
                    serviceDetails: `${el.service_details.service_type} ${(el.service_details.position) ? el.service_details.position.replace('N/A', '') : ''} ${(el.service_details.part) ? el.service_details.part.replace('N/A', '') : ''}`,
                    payload: [{ serviceID: el.id }],
                };
            }));
            return (
                <Paper key={key} elevation={0} sx={{ backgroundColor: theme.palette.grey[50], width: '100%', p:1, my:2 }}>
                    <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography sx={{ fontSize: 16, fontWeight: 400 }} color={theme.palette.grey[900]}> { rep } </Typography>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }} color={theme.palette.grey[900]}> { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total) } </Typography>
                            <IconButton
                                onClick={(event) => {setcurrentOpenMenu(key); handleClickMenu(event.currentTarget);}}
                            >
                                <MoreVertIcon sx={{ color: theme.palette.grey[500] }} />
                            </IconButton>
                            <Menu
                                id={`long-menu-${rep}`}
                                anchorEl={anchorElMenu}
                                open={Boolean(anchorElMenu) && currentOpenMenu === key}
                                onClose={handleCloseMenu}
                            >
                                {closeOpts.map((option, j) => (
                                    <MenuItem key={j} onClick={() => handleCancelMenuItem(option)} sx={{ '&:hover': { backgroundColor: theme.palette.blue[30] } }}>
                                        { option.label }
                                    </MenuItem>
                                ))}
                                {reworkOpts.map((option, j) => (
                                    <div key={j}>
                                        <MenuItem key={j} onClick={() => handleReworkMenuItem(option)} sx={{ '&:hover': { backgroundColor: theme.palette.blue[30] } }}>
                                            {/* Set Up ReWork Modal */}
                                            {option.label}
                                        </MenuItem>
                                        { reworkModalOpen && (
                                            <ServiceReworkModal
                                                open={reworkModalOpen}
                                                setOpen={setRWModalOpen}
                                                serviceID={option.serviceID}
                                                serviceDetailIDs={`${option.serviceDetailIDs}`}
                                                serviceDetails={option.serviceDetails}
                                                serviceMedia={['serviceMedia']}
                                                serviceNotes={['serviceNotes']}
                                                repairCatalog={['repairCatalog']}
                                                DISPATCH={() => dispatch({ type: 'set_active_card', payload: 0 })}
                                            />
                                        )}
                                    </div>
                                ))}
                            </Menu>
                        </Box>
                    </Grid>
                    <Grid justifyContent={'space-evenly'} alignItems={'center'}>
                        {(() => {
                            return serviceList.map((service, i) => {
                                return (
                                    <div key={i}>
                                        <Card variant='outlined' sx={{ borderRadius: 2, my: 2, p: 2 }}>
                                            <Grid display={'flex'} justifyContent={'space-between'}p={0} m={0} mb={1.5} alignItems={'center'}>
                                                <Grid display={'flex'} alignItems={'center'}>
                                                    <Typography sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}>
                                                        { service.service_details.repair_type || service.service_details.service_type }
                                                    </Typography>
                                                    &nbsp;
                                                    <Typography sx={{ fontSize: 12, fontWeight: 400 }} color={theme.palette.grey[500]}>
                                                        { `${(service.service_details.position) ? service.service_details.position.replace('N/A', '') : ''}
                                                      ${(service.service_details.part) ? service.service_details.part.replace('N/A', '') : ''}` }
                                                    </Typography>
                                                </Grid>
                                                <Grid display={'flex'} gap={1}>
                                                    { service.service_history && service.service_history.length && service.service_history.map(el => el.price).filter(el => el && el != service.price).map((el, ind) => {
                                                        return ( <Typography key={ind} color={theme.palette.grey[500]} sx={{ fontSize: 12, textDecoration: 'line-through', fontWeight: 300 }}> { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(el) } </Typography> );
                                                    }) }
                                                    <Typography sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.grey[900]} display={'flex'}>
                                                        { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(service.price) }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                display={'flex'}
                                                justifyContent={'start'}
                                                alignItems={'center'} p={0} m={0} my={0.5}
                                            >
                                                <Card
                                                    variant={STATUS_BAR_CLASS[service.service_status]}
                                                >
                                                    { STATUS_BAR_DISPLAY[service.service_status] }
                                                </Card>
                                                { (service.supplement && service.supplement.status) && (
                                                    <Card
                                                        variant={STATUS_BAR_CLASS[service.supplement.status]}
                                                    >
                                                        { `Supplement ${service.supplement.status}` }
                                                    </Card>
                                                )}
                                                { service.rework && (
                                                    <Card variant={'REWORK'}>Rework</Card>
                                                )}
                                            </Grid>
                                            <Divider />
                                            {
                                                (() => {
                                                    if (service.media && service.media.length) {
                                                        return (
                                                            <Grid
                                                                justifyContent={'start'}
                                                                alignItems={'center'} p={0} m={0} my={1.5} gap={1}
                                                            >
                                                                { service.media.map((serv) => {
                                                                    return serv.submission_content
                                                                        .filter(m => m.media_type == 'Text').map((el, i) => {
                                                                            return (
                                                                                <Typography
                                                                                    key={i}
                                                                                    sx={{ fontSize: 14, fontWeight: 400 }}
                                                                                    color={theme.palette.grey[900]}
                                                                                >
                                                                                    { el.text }
                                                                                </Typography>
                                                                            );
                                                                        });
                                                                })}
                                                            </Grid>
                                                        );
                                                    }
                                                }
                                                )()
                                            }
                                            {(() => {
                                                if (service.media && service.media.length) {
                                                    const formatThumbnail = function(med) {
                                                        if (med.media_type === 'PDF') {
                                                            return <iframe width='70' height='70' src={med.url}></iframe>;
                                                        } else if (med.media_type === 'Video') {
                                                            return <video width='70' height='70' controls>
                                                                <source src={med.url} type='video/mp4' />
                                                            </video>;
                                                        }
                                                        return <img
                                                            src={med.url}
                                                            width='70' height='70'
                                                            style={{}}
                                                        />;
                                                    };
                                                    return (
                                                        <Grid display={'flex'} justifyContent={'start'} alignItems={'center'} p={0} m={0} my={1.5} gap={1}>
                                                            { service.media.map((serv) => {
                                                                return serv.submission_content
                                                                    .filter(m => m.media_type != 'Text').map((el, i) => {
                                                                        return (
                                                                            <Box key={i}>
                                                                                <a href={el.url} target='_blank' rel='noreferrer'>
                                                                                    { formatThumbnail(el) }
                                                                                </a>
                                                                            </Box>
                                                                        );
                                                                    });
                                                            })}
                                                        </Grid>
                                                    );
                                                }
                                            }
                                            )()}
                                            {(() => {
                                                if ((service.notes && service.notes.length) || (service.media && service.media.length)) {
                                                    return <Divider />;
                                                }
                                            }
                                            )()}
                                            <Button fullWidth={true} title={`btn-edt-${i}`} onClick={() => handleHistoryView(service, i)} disabled={viewingHistory[i]}>
                                                <Grid sx={{ width:'100%' }} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={0} m={0} my={.5}>
                                                    <Typography sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.blue[500]}> History </Typography>
                                                    <ChevronRight />
                                                </Grid>
                                            </Button>

                                            { viewing == service.id ? (<ServiceHistory
                                                open={viewing == service.id}
                                                onClose={() => handleHistoryViewClose()}
                                                service={service}
                                                supplier={state.job['supplier_company_name']}
                                            />) : null }

                                            { STATUS_BAR_DISPLAY[service.service_status] == 'Pending' && (service.supplement && service.supplement.status) && (
                                                <Card variant={'outlined'} elevation={0} sx={{ width:'100%', p: 1, borderRadius: 2 }}>
                                                    <Grid display={'flex'} justifyContent={'center'}>
                                                        {/* @ts-ignore */}
                                                        <Card variant={'SUPPLEMENT_PENDING'}> Supplement Pending </Card>
                                                    </Grid>
                                                    <Grid display={'flex'} justifyContent={'center'} mb={1}>
                                                        <Typography variant={'h6'}> { `Service has pending supplemental work from ${rep}` } </Typography>
                                                    </Grid>
                                                    <Grid display={'flex'} justifyContent={'center'}>
                                                        <ApproveButton variant='contained' onClick={() => {handleSupplement('approve', service.id);}}> Approve </ApproveButton>
                                                        <DeclineButton variant='contained' onClick={() => {handleSupplement('decline', service.id, (service.service_history) ? service.service_history.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0].price : service.price);}}> Decline </DeclineButton>
                                                    </Grid>
                                                </Card>
                                            )}
                                        </Card>
                                    </div>
                                );
                            });

                        })() }
                    </Grid>
                </Paper>
            );
        });
        return (
            <Grid container display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} px={2} my={1}>
                <Typography display={'flex'} alignSelf={'center'} my={1} sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}> Services by Repairer </Typography>
                <Grid container display={'flex'} flexDirection={'row'} justifyContent={'center'} alignContent={'center'}>
                    { repServ }
                </Grid>
            </Grid>
        );
    };

    const getServiceBoard = function() {
        let data = [];
        let j = 1;
        const sList = [
            { status: ['OPEN'],
                display: 'Open',
                bgColor: theme.palette.blue[50],
                altColor: theme.palette.blue[600],
            },
            { status: ['PENDING', 'PENDING APPROVAL'],
                display: 'Pending',
                bgColor: theme.palette.yellow[40],
                altColor: theme.palette.yellow[700],
            },
            { status: ['ACCEPTED'],
                display: 'Accepted',
                bgColor: theme.palette.green[30],
                altColor: theme.palette.green[600],
            },
            { status: ['IN PROGRESS'],
                display: 'In Progress',
                bgColor: theme.palette.blue[50],
                altColor: theme.palette.blue[600],
            },
            { status: ['COMPLETED', 'INVOICED', 'CLOSED'],
                display: 'Complete',
                bgColor: theme.palette.green[40],
                altColor: theme.palette.green[600],
            },
            { status: ['DECLINED'],
                display: 'Declined',
                bgColor: theme.palette.red[30],
                altColor: theme.palette.red[600],
            },
            { status: ['CANCELLED'],
                display: 'Cancelled',
                bgColor: theme.palette.grey[100],
                altColor: theme.palette.black,
            },
        ];

        for (let s of sList) {
            // @ts-ignore
            const filteredList = state.job.services.filter(el => s.status.includes(el.service_status.toUpperCase()));
            // eslint-disable-next-line no-constant-condition
            if (filteredList.length) {
                // @ts-ignore
                const repList = [...new Set(filteredList.map(el => el.repair_company.name))].sort();
                let details = [];
                repList.forEach((rep, i) => {
                    const rep_ = filteredList.filter(el => el.repair_company.name === rep); // All services with this Status for this Repairer
                    // @ts-ignore
                    const servSet = [...new Set(filteredList.map(el => el.service_details.service_type))].sort();
                    const servDets = servSet.map((sev, k) => {
                        const sev_ = rep_.filter(el => el.service_details.service_type === sev).map(el => {
                            return `${(el.service_details.position && el.service_details.position != 'N/A' && el.service_details.position != null)
                                ? el.service_details.position : ''}${(el.service_details.part && el.service_details.part != 'N/A' && el.service_details.part != null)
                                ? ' '+el.service_details.part : ''}`;
                        });
                        return (
                            <Grid key={k} display={'flex'} alignItems={'center'}>
                                <Typography sx={{ fontSize: 12, fontWeight: 500 }}> { sev } </Typography>
                                <Typography sx={{ fontSize: 12, fontWeight: 300, ml: 1 }}>
                                  &nbsp;{ `${(sev_ && sev_.length && sev_[0] != '') ? `${sev_.join(', ')}` : ''}` }
                                </Typography>
                            </Grid>
                        );
                    });
                    // @ts-ignore
                    const supplementFlag = [...new Set([...servSet.reduce((total, sev) => {
                        total.push(...rep_.filter(el => el.service_details.service_type === sev).map(el => {
                            if (el.supplement && el.supplement.status) {
                                return el.supplement.status;
                            }
                        }));
                        return total;
                    }, [])])]
                        .filter(el => el)
                        .map((el, k) => <Card key={k} variant={STATUS_BAR_CLASS[el]}> { `Supplement ${el}` } </Card>);
                    // @ts-ignore
                    const reworkFlag = [...new Set([...servSet.reduce((total, sev) => {
                        total.push(...rep_.filter(el => el.service_details.service_type === sev).map(el => {
                            if (el.rework) {
                                return 'Rework';
                            }
                        }));
                        return total;
                    }, [])])]
                        .filter(el => el)
                        .map((el, k) => <Card key={k} variant={'REWORK'}> { el } </Card>);
                    details.push(
                        <Paper key={i} elevation={0} variant={'outlined'} sx={{ m: 1, p: 1, fontSize: 11 }}>
                            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography sx={{ fontSize: 12, fontWeight: 500 }}> { rep } </Typography>
                                <Typography sx={{ fontSize: 12, fontWeight: 500 }}> { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rep_.reduce((sum, part) => sum += part.price, 0)) }  </Typography>
                            </Grid>
                            <Divider />
                            { servDets }
                            { supplementFlag }
                            { reworkFlag }
                        </Paper>,
                    );
                });
                data.push(
                    <Grid key={j} sx={{ minWidth: '33%', width: '100%', backgroundColor: theme.palette.grey[30], mx: .5 }}>
                        <Box sx={{ borderBottom: '1px solid', borderBottomColor: s.altColor, borderRadius: 0, backgroundColor: s.bgColor }} py={.5} display={'flex'} justifyContent={'center'}>
                            <Typography> { s.display } &nbsp; </Typography>
                            <Typography color={s.altColor}> { `${filteredList.length}` } </Typography>
                        </Box>
                        <Box height={205} overflow={'auto'}> { details.map((el) => {
                            return el;
                        })} </Box>
                    </Grid>,
                );
                j++;
            }
        }
        if (data.length > 1) {
            return (
                <Grid container display={'flex'} justifyContent={'center'} alignContent={'center'} px={2} my={1} gap={1}>
                    <Typography my={1} sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}> { 'Services by Status' } </Typography>
                    <Grid display={'flex'} sx={{ width: '100%' }} overflow={'auto'}>
                        { data }
                    </Grid>
                </Grid>
            );
        }
        return <></>;
    };
    useEffect(() => {
        if (state.job.services && state.job.services.length && !viewingHistory.length) resetViewing();
    }, [viewing, viewingHistory]);

    return (
        <Paper elevation={0} sx={{ pb: 4, width: '100%' }}>
            { state.job.vehicle && state.job.services && (
                <Grid container justifyContent={'center'} display={'flex'}>
                    <Grid item sx={{ height: '50px', width: '100%', px: 2 }} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> { getDateCreated() } </Typography>
                        { state.job.services.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).length > 0 && (
                            <Button
                                sx={{
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                    borderRadius: theme.spacing(1),
                                    padding: '2px 8px',
                                    marginRight: theme.spacing(1.5),
                                    marginLeft: theme.spacing(1.5),
                                    background: theme.palette.blue.main,
                                    color: theme.palette.white,
                                    '&: hover': {
                                        background: theme.palette.blue.main,
                                        color: theme.palette.white,
                                    },
                                }}
                                onClick={() => {
                                    handleCancelButton({ payload: state.job.services.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).map(el => ({ serviceID: el.id })) });
                                }}
                            > Cancel Job </Button>
                        )}
                    </Grid>
                    <Divider style={{ width: '95%', marginTop: 1, marginBottom: 1 }} />
                    <Typography mt={1} display={'flex'} sx={{ width: '100%', px: 2, fontSize: 20, fontWeight: 500 }}> { getYYMC() } </Typography>
                    <Grid item display={'flex'} sx={{ width: '100%', px: 2 }} justifyContent={'space-between'} my={1}>
                        <Grid item>
                            <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> { getStockRO() } </Typography>
                            <Typography sx={{ fontSize: 12, fontWeight: 300, textTransform: 'uppercase' }} color={theme.palette.grey[500]}> { getVIN() } </Typography>
                            <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> { getLocation() } </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> { getPO() } </Typography>
                        {/* <ChatButton> <ChatBubbleOutline /> </ChatButton> */}
                    </Grid>
                    <Divider style={{ width: '95%', marginTop: 1, marginBottom: 1 }} />
                    <Grid item display={'flex'} sx={{ width: '100%', px: 2 }} justifyContent={'space-between'} alignItems={'center'} my={1}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ServiceIcon />
                            <Typography mx={1} sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.grey[900]}> { state.job.services.length } </Typography>
                            <RepairIcon />
                            <Typography mx={1} sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.grey[900]}> { [...new Set(state.job.services.map(el => el.repair_company.name))].length } </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}> { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(state.job.services.reduce((sum, part) => sum += part.price, 0)) } </Typography>
                        {/* <ChatButton> <ChatBubbleOutline /> </ChatButton> */}
                    </Grid>
                    <Divider style={{ width: '95%', marginTop: 2 }} />
                    { getServiceBoard() }
                    { getRepairerCards() }

                </Grid>
            )}

        </Paper>
    );
};

JobDetails.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
};

export default JobDetails;