
import React, { useEffect } from 'react';

import { Paper, useTheme } from '@mui/material';

import { useError } from 'contexts/ErrorContext';
import { useLocations, actions } from 'services/location';

import Card from 'components/Cards/MainCard';

export default function LocationList() {
    const theme = useTheme();
    const locationContext = useLocations();
    const err = useError();

    const formatLocation = function (location) {
        if (location) {
            return {
                id: location.id,
                title: location.nickname,
                subTitle: location.formatted_address,
            };
        }
    };

    const selectLocation = async function (id) {
        actions.setActiveLocation(id, locationContext, err);
    };

    const locationCards = locationContext.state.locations
        .sort((a, b) => (a['formatted_address'] || '')
            .localeCompare(b['formatted_address'], undefined, { numeric: true, sensitivity: 'base' }))
        .map((location) => (
            <Card
                key={location.id}
                content={formatLocation(location) }
                selected={locationContext.state.activeLocationId === location.id}
                active={true} // MTODO: set as location.active when RoDx API supports location status (active / inactive)
                onClick={selectLocation}
            />
        ));

    useEffect(() => {
        if (!locationContext.state.activeLocationId) {
            selectLocation(locationContext.state?.locations?.[0]?.id);
        }
    }, [locationContext.state.activeLocationId]);

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: theme.palette.background.default,
                px: .5,
                pb: 1,
                height: '100%',
                maxHeight: '100%',
                overflowY: 'auto',
            }}
        >
            { locationCards }
        </Paper>
    );
}
