import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, Grid, TextField, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function DropDown({ label, icon, options, dispatch, cb }) {
    // const [value, setValue] = useState([]);
    const [pendingValue, setPendingValue] = useState([]);
    const theme = useTheme();

    // useEffect(() => {
    //     if (initial !== null) setValue([options[initial]]);
    // }, []);

    // useEffect(() => {
    //     if (!label) label = value[0];
    // }, [value]);

    const handleChange = () => {
        // setValue(pendingValue);
        switch (label) {
            case 'Locations':
                dispatch({ type: 'select_locations', payload: pendingValue.map((e) => e.id) });
                cb({ 'locations': pendingValue.map((e) => e.id) });
                break;
            case 'Repairers':
                dispatch({ type: 'select_repairers', payload: pendingValue.map((e) => e.id) });
                cb({ 'repairers': pendingValue.map((e) => e.id) });
                break;
            // case 'Status':
            //     dispatch({ type: 'select_status', payload: pendingValue.map((e) => e.id) });
            //     break;
            case 'Services':
                dispatch({ type: 'select_services', payload: pendingValue.map((e) => e.id) });
                cb({ 'service_types': pendingValue.map((e) => e.name) });
                break;
            case 'Sort':
                // @ts-ignore
                dispatch({ type: 'select_sort', payload: pendingValue.id });
                break;
            default:
                break;
        }
    };

    const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    return (
        <Grid display={'flex'} alignItems={'center'}
            sx={{
                width: '100%',
                maxWidth: '100%',
                maxHeight: 32,
                borderRadius: 2,
                border: 1.5,
                borderColor: theme.palette.grey[100],
                px: 0,
                mx: 1 }}>
            { icon }
            <Autocomplete
                autoComplete={false}
                isOptionEqualToValue={(option, value) => { return (option.id == value.id); }}
                options={options}
                getOptionLabel={(option) => option.name}
                sx={{
                    border: 0,
                    width: '100%',
                }}
                disableCloseOnSelect
                ListboxProps={{ sx: { fontSize: 14, padding: 1 } }}
                multiple
                limitTags={0}
                onChange={(event, newValue, reason) => {
                    // @ts-ignore
                    if ( event.key === 'Backspace' && event.type === 'keydown' && reason === 'removeOption' ) return;
                    setPendingValue(newValue);
                }}
                disableClearable={true}
                onClose={handleChange} // Only triggers change when component is closed
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='subtitle2' sx={{ fontSize: '12px' }}>
                                { option.name }
                            </Typography>
                            <Checkbox
                                icon={uncheckedIcon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 10 }}
                                checked={selected}
                            />
                        </Grid>
                    </li>
                )}
                renderTags={(tagValue) => {
                    return tagValue.map((option, index) => {
                        return (
                            <Typography key={index}> { option.name } &nbsp; </Typography>
                        );
                    });
                }}
                renderInput={(params) => {
                    params.InputProps = { ...params.InputProps,
                        // @ts-ignore
                        style: { padding: 0, fontSize: 14 },
                    };
                    return (
                        <TextField {...params} placeholder={label} sx={{ maxHeight: 32,
                            p: .5,
                            input: {
                                '&::placeholder': {
                                    fontSize: 14, fontWeight: 500, color: theme.palette.grey[900], opacity: 1,
                                } } }}
                        />
                    );
                }}
            />
        </Grid>
    );
}

DropDown.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.node,
    options: PropTypes.array,
    initial: PropTypes.number,
    multi: PropTypes.bool,
    cb: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
};