// @ts-nocheck
import locationAPI from './api';
import TYPES from './types';
import { validateRequired, validatePhone, validateAddress, validateEmail, validateObject } from '../utils/validation';

const LOCATION_PROP_VALIDATION = [
    [validateRequired, 'nickname', 'Location Name'],
    [[validateRequired, validateAddress], 'address', 'Address', (location) => location],
    [validatePhone, 'contact_info.phone_number', 'Phone Number'],
    [validateEmail, 'contact_info.email', 'Email'],
];

export const getLocations = async (params, successCB, errorCB) => {
    const handleError = (error) => {
        if (errorCB && typeof errorCB === 'function') {
            errorCB(error);
        } else {
            throw new Error(`Error loading locations: ${JSON.stringify(error)}`);
        }
    };

    try {
        const response = await locationAPI.getLocations();

        if (response && response.data.status === 200 && response.data.payload) {
            if (successCB && typeof successCB === 'function') {
                successCB(response.data.payload);
            }
            return response.data.payload;
        }
        handleError({ status: response.data.status, type: 'API_ERROR', msg: response?.message ?? response });
    } catch (error) {
        handleError({ type: 'API_ERROR', msg: error });
    }
};

export const refreshLocations = async (params, locationContext, errContext, successCB, errorCB) => {
    const onLocationsLoadError = ({ status, type, msg }) => {
        errContext.DISPATCH({
            type,
            payload: { status, alert: msg },
        });
        if (errorCB && typeof errorCB === 'function') {
            errorCB({ status, type, msg });
        }
    };

    const onLocationsLoaded = (locations) => {
        // MTODO: why dispatch error on success, maybe should be called something else??
        onLocationsLoadError({ status: 200, type: 'NO_ERROR', msg: 'Locations loaded!' });

        locationContext.DISPATCH({
            type: TYPES.SET_LOCATIONS,
            payload: locations.filter(el => el),
        });

        if (successCB && typeof successCB === 'function') {
            successCB(locations);
        }
    };

    await getLocations(params, onLocationsLoaded, onLocationsLoadError);
};

export const refreshLocation = async (id, locationContext) => {
    const res = await locationAPI.getLocationById(id);

    if (res?.data?.status === 200 && res?.data?.payload) {
        locationContext.DISPATCH({ type: TYPES.SET_LOCATION, payload: res.data.payload });
    } else {
        throw new Error(`Error retrieving location: ${JSON.stringify(res)}`);
        // TODO: Let's move this to a Context Error so that it can be caught, currently throwing an uncatchable error
        // errContext.DISPATCH({ type: 'Error retrieving location', payload: error });
    }
};

export const setActiveLocation = async (id, locationContext, errContext) => {
    const curId = locationContext.state.activeLocationId;

    if (id === curId) {
        return;
    }

    locationContext.DISPATCH({ type: TYPES.SET_ACTIVE_LOCATION, payload: { id } });

    try {
        const promises = [refreshLocation(id, locationContext, true)];

        if (curId) {
            promises.push(refreshLocation(curId, locationContext, false));
        }

        await Promise.all(promises);

        errContext.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Location loaded!', status: 200 } });
        validateActiveLocation();
    } catch (error) {
        errContext.DISPATCH({ type: 'API_ERROR', payload: error });
    }
};

export const createLocation = async (newLocation, locationContext, errContext) => {
    try {
        const res = await locationAPI.createLocation({ data: newLocation });

        if (res?.data?.status === 201 && res?.data?.payload) {
            const newLocation = res.data.payload;
            locationContext.DISPATCH({ type: TYPES.CREATE_LOCATION, payload: newLocation });
            errContext.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Location created!', status: res.data.status } });
            await setActiveLocation(newLocation.id, locationContext, errContext);
        }
    } catch (error) {
        errContext.DISPATCH({ type: 'API_ERROR', payload: error });
    }
};

export const updateLocation = async (location, locationContext, errContext) => {
    try {
        const res = await locationAPI.updateLocation({ data: location });
        if (res?.data?.status === 200 && res?.data?.payload) {
            locationContext.DISPATCH({ type: TYPES.UPDATE_LOCATION, payload: res.data.payload });
            errContext.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Location updated!', status: res.data.status } });
        }
    } catch (error) {
        errContext.DISPATCH({ type: 'API_ERROR', payload: error });
    }
};

export const validateActiveLocation = (locationContext) => {
    const location = locationContext.state.locations.find((loc) => loc.id && loc.id === locationContext?.state?.activeLocationId);

    const validationMsgs = validateObject(location, LOCATION_PROP_VALIDATION);

    locationContext.DISPATCH({
        type: TYPES.CHANGE_LOCATION_VALIDATION,
        payload: validationMsgs,
    });
};

export const validateNewLocation = (locationContext) => {
    const location = locationContext?.state?.newLocation || {};

    const validationMsgs = validateObject(location, LOCATION_PROP_VALIDATION);

    locationContext.DISPATCH({
        type: TYPES.CHANGE_NEW_LOCATION_VALIDATION,
        payload: validationMsgs,
    });
};
