import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Stack, Button, Divider, Box } from '@mui/material';

import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ButtonCard from 'components/Cards/ButtonCard';
import IconContainer from 'components/Icons/IconContainer';

import { StyledTitle, NotificationCardStyles } from './styles';

const filterValues = [
    {
        id: 'all',
        title: 'All',
        value: null,
    },
    {
        id: 'unread',
        title: 'Unread',
        value: false,
    },
    {
        id: 'read',
        title: 'Read',
        value: true,
    },
];

const NotificationList = ({ notifications, onClick }) => {
    const theme = useTheme();

    const [filterRead, setFilterRead] = React.useState(null);
    const filteredNotifications = notifications
        .filter((notification) => filterRead == null ? true : notification.read === filterRead)
        .sort((a, b) => {
            if (!a.timestamp) {
                return 1;
            }
            if (!b.timestamp) {
                return -1;
            }
            return b.timestamp - a.timestamp;
        });

    console.log('filteredNotifications', filteredNotifications);

    const notificationListElem = filteredNotifications.map((notification) => {
        const iconConfig = NotificationCardStyles(theme)[notification.title] ?? { };

        return (
            <Stack direction='row' spacing={0} key={notification.id} width='95%'>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '10px', minWidth: '10px', maxWidth: '10px' }}>
                    <FiberManualRecordIcon style={{
                        width: '10px',
                        height: '10px',
                        margin: 0,
                        position: 'relative',
                        left: '-2px',
                        color: notification.read ? 'transparent': null }}
                    />
                </Box>
                <ButtonCard
                    onClick={() => onClick(notification)}
                    content={{
                        id: notification.id,
                        name: notification.title,
                        description: notification.body || '',
                        text: `1 day ago${notification.read ? ' · Read' : ''}`,
                    }}
                    active
                    showStatus={false}
                    showBorder={false}
                    compact={true}
                    iconVerticalAlign='center'
                    iconElement={
                        <IconContainer
                            icon={iconConfig.icon ?? WorkOutlineIcon}
                            backgroundCircle
                            sx={{ background: iconConfig.color ?? 'red', color: 'white' }}
                            iconProps={{ style: { color: 'white', width: '15px', height: '15px' } }}
                            width='25px'
                            height='25px'
                        />
                    }
                />
            </Stack>
        );
    });

    return (
        <Stack direction='column' m={1} minWidth='300px'>
            <Stack direction='column' spacing={1.5} style={{ marginLeft: '10px' }}>
                <StyledTitle variant='h2'>
                    Notifications
                </StyledTitle>
                <Stack direction='row' spacing={2}>
                    {filterValues.map((filter) => (
                        <Button
                            key={filter.id}
                            onClick={() => setFilterRead(filter.value)}
                            variant='contained'
                            sx={{
                                backgroundColor: filterRead === filter.value ? theme.palette.blue[100] : theme.palette.grey[30],
                                color: filterRead === filter.value ? theme.palette.blue[800] : theme.palette.grey[500],
                                ':hover': {
                                    color: theme.palette.white,
                                },
                                textTransform: 'uppercase',
                            }}
                        >
                            {filter.title}
                        </Button>
                    ))}
                </Stack>
            </Stack>
            <Box style={{ maxHeight: '300px', overflow: 'auto' }}>
                <Stack
                    direction='column'
                    spacing={1}
                    style={{ marginTop: '15px' }}
                    divider={
                        <Box width='100%' display='flex' justifyContent='center'>
                            <Divider orientation='horizontal' flexItem sx={{ width: '90%' }} />
                        </Box>
                    }>
                    {notificationListElem}
                </Stack>
            </Box>
        </Stack>
    );
};

NotificationList.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            body: PropTypes.string,
            read: PropTypes.bool.isRequired,
            timestamp: PropTypes.number,
        }),
    ).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default NotificationList;
