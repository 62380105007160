import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import LocationReducer, { INITIAL_STATE, INIT } from './reducer';

const LocationContext = createContext({
    DISPATCH: null,
    state: {},
});

export const LocationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(LocationReducer, { ...INITIAL_STATE }, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
    };

    return (
        <LocationContext.Provider value={value}> { children } </LocationContext.Provider>
    );
};

LocationProvider.propTypes = {
    children: PropTypes.node,
};

export const useLocations = () => useContext(LocationContext);
