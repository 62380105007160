import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    IconButton,
    Grid,
    Paper,
    Typography,
    styled,
    useTheme,
} from '@mui/material';

import { ControlPoint as ControlPointIcon, Refresh as RefreshIcon, Place, SortOutlined } from '@mui/icons-material';
import { ReactComponent as Repairer } from 'assets/images/repairer.svg';
import { ReactComponent as Services } from 'assets/images/services.svg';

import { useJobs } from 'services/job';
import { SERVICES } from 'services/utils/enums';
import { useError } from 'contexts/ErrorContext';
import Loading from 'components/Loading';

import Search from './content/Search';
import DatePicker from './content/DatePicker';
import DropDown from './content/DropDown';
import JobList from './content/JobList';
import JobDetails from './content/JobDetails';
import './styles.css';

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    width: '100%',
}));

const NewJobButton = styled(Button)(({ theme }) => ({
    borderRadius: '8px',
    background: theme.palette.blue.main,
    color: 'white',
    '& .MuiButton-startIcon': {
        color: theme.palette.blue[300],
    },
}));

const JobsScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const errorContext = useError();
    const jobsContext = useJobs();

    const [timeReload, setTimeReload] = useState(false);

    const setFilterParams = (params) => {
        jobsContext.actions.setFilterParams(params);
    };

    const getAllJobs = () => {
        jobsContext.actions.getAllJobs({ requestParams: jobsContext.state.filterParams }, errorContext);
        setTimeReload(false);
    };

    const pills = [ 'All Jobs', 'Jobs with Open Work', 'Jobs with Closed Work'];
    const quickTabs = {
        'Jobs with Open Work': ['Open', 'Accepted', 'In Progress', 'Supplemental'],
        'Jobs with Closed Work': ['Completed', 'Cancelled'],
        'All Jobs': [],
    };

    const [currentPill, setCurrentPill] = useState(pills[0]);
    const handlePillClick = async function (pill) {
        setCurrentPill(pill);
        setCurrentQT('');
        const quickTabsMap = {
            'Jobs with Open Work': ['Open', 'Pending Approval', 'Accepted', 'In Progress', 'Rework', 'Declined'],
            'Jobs with Closed Work': ['Completed', 'Closed', 'Invoiced', 'Cancelled'],
        };
        if (pill != 'All Jobs' ) { setFilterParams({ 'service_statuses': quickTabsMap[pill] }); }
        else { setFilterParams({ 'service_statuses': '' }); }
    };

    const pillBar = function () {
        return pills.map((pill, i) => {
            return (<Grid item key={i}>
                <Button
                    onClick={() => handlePillClick(pill)}
                    sx={{ p: 0.5,
                        m: 0.5,
                        fontSize: '16px',
                        textTransform: 'none',
                        color: (pill === currentPill) ? theme.palette.blue[500] : theme.palette.grey[500],
                    }}
                    className={(pill === currentPill) ? 'active': ''}
                > { pill } </Button>
            </Grid>);
        });
    };

    const [currentQT, setCurrentQT] = useState('All Jobs');
    const statusMap = {
        'Open': ['Open'],
        'Accepted': ['Accepted'],
        'In Progress': ['In Progress'],
        'Completed': ['Completed', 'Closed', 'Invoiced'],
        'Cancelled': ['Cancelled'],
        'Supplemental': ['Pending Approval', 'Rework'],
    };

    const handleQTClick = async function (qt) {
        setCurrentQT(qt);
        setFilterParams({ 'service_statuses': statusMap[qt] });
    };

    const quickTabBar = function (current) {
        return quickTabs[current].map((q, i) => {
            // let count = 0;
            // for (const k of statusMap[q]) {
            //     count = count + (jobsContext.state.count.num_jobs_containing_status[k] || 0);
            // }
            return (<Grid item key={i}>
                <Button
                    variant={'contained'}
                    onClick={() => handleQTClick(q)}
                    sx={{ py: .5,
                        px: 3,
                        m: 1,
                        fontSize: '16px',
                        textTransform: 'none',
                        fontWeight: 400,
                        color: (q === currentQT) ? theme.palette.white : theme.palette.black,
                        backgroundColor: (q === currentQT) ? theme.palette.blue[800] : theme.palette.grey[50],
                        '&:hover': {
                            color: theme.palette.blue[500],
                            backgroundColor: theme.palette.blue[800],
                        },
                    }}
                > { q } </Button>
                {/* > { `${q} - ${count}` } </Button> */}
            </Grid>);
        });
    };

    useEffect(() => {
        if (!(jobsContext?.state?.loaded || false)) {
            getAllJobs();
            // if (!jobsContext.state.count || jobsContext.state.count.num_jobs == null) {
            //     jobsContext.actions.loadJobCount(errorContext);
            // }
        }
        if (!jobsContext.state.locations || !jobsContext.state.locations.length) {
            jobsContext.actions.loadLocations();
        }
        if (!jobsContext.state.repairers || !jobsContext.state.repairers.length) {
            jobsContext.actions.loadRepairers();
        }
    }, [jobsContext?.state?.loaded, timeReload]);

    const jobsListElem = !(jobsContext?.state?.loaded || false) ? (
        <Loading loading fullScreen={false} width={150} height={150} />
    ): (
        <>
            <Box sx={{ height: '50px', mx: 1 }} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                <DropDown
                    label={'Sort'}
                    dispatch={jobsContext.DISPATCH} state={jobsContext.state}
                    multi={false}
                    icon={<SortOutlined
                        width={'20px'} height={'20px'}
                        sx={{ marginRight: '5px', color: theme.palette.blue.main }}
                    />}
                    options={jobsContext.state.sort.map((el) => {
                        return el;
                    })}
                    cb={setFilterParams}
                />
                <IconButton onClick={() => getAllJobs() }> <RefreshIcon /> </IconButton>
            </Box>
            <JobList />
        </>
    );

    const jobsDetailsElem = !jobsContext?.state?.loaded ? (
        <Loading loading fullScreen={false} width={150} height={150} />
    ): (
        <JobDetails state={jobsContext.state} dispatch={jobsContext.DISPATCH} />
    );

    return (
        <Grid container display={'flex'}>
            <Grid item container display={'flex'} justifyContent={'space-between'}>
                <Grid item mb={2}>
                    <Typography variant='h1' display={'flex'} alignItems={'center'}>
                        Jobs
                    </Typography>
                </Grid>
                <Grid item container display={'flex'} justifyContent={'space-between'} mb={2} alignItems={'center'}>
                    <Box display={'flex'}>
                        { pillBar() }
                    </Box>
                    <NewJobButton
                        variant='contained'
                        onClick={() => navigate('/new_job')}
                        startIcon={<ControlPointIcon />}
                    >
                        New Job
                    </NewJobButton>
                </Grid>
            </Grid>
            <Grid item container display={'flex'}>
                <ContentContainer sx={{ p:0, mb: 6 }}>
                    { currentPill != 'Cancelled' &&
                        <Grid display={'flex'}>
                            { quickTabBar(currentPill) }
                        </Grid>
                    }
                    <Grid item alignContent={'center'} justifyContent={'space-between'} alignItems={'center'} p={1} display={'flex'}
                        sx={{ borderTop: '1px solid', borderBottom: '1px solid', borderColor: theme.palette.grey[50] }}
                    >
                        <Grid display={'flex'} gap={1}>
                            <Search dispatch={jobsContext.DISPATCH} state={jobsContext.state} disabled={true} />
                        </Grid>
                        <Grid item display={'flex'} mx={1} xs={9} alignItems={'center'} justifyContent={'end'}>
                            <DatePicker
                                dispatch={jobsContext.DISPATCH}
                                state={jobsContext.state}
                                setJobsLoaded={() => { /* TODO: remove */ }}
                            />
                            <DropDown
                                label={'Locations'}
                                dispatch={jobsContext.DISPATCH} state={jobsContext.state}
                                icon={<Place width={'20px'} height={'20px'} sx={{ m: 1, color: theme.palette.blue.main }} />}
                                options={(jobsContext.state.locations || []).map((el) => {
                                    return { ...el, name: el.nickname };
                                })}
                                cb={setFilterParams}
                            />
                            <DropDown
                                label={'Repairers'}
                                dispatch={jobsContext.DISPATCH} state={jobsContext.state}
                                icon={<Repairer style={{ margin: 4 }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />}
                                options={(jobsContext.state.repairers || []).map((el) => {
                                    return el;
                                })}
                                cb={setFilterParams}
                            />
                            {/* <DropDown
                                label={'Supplemental'}
                                dispatch={jobsContext.DISPATCH} state={jobsContext.state}
                                icon={<Services style={{ margin: 4 }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />}
                                options={jobsContext.state.supplements.map((el) => {
                                    return {...el, name: el.name.replace('_',' ').toLowerCase()};
                                })}
                            /> */}
                            <DropDown
                                label={'Services'}
                                dispatch={jobsContext.DISPATCH} state={jobsContext.state}
                                icon={<Services style={{ margin: 4 }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />}
                                options={SERVICES.map((el) => {
                                    return el;
                                })}
                                cb={setFilterParams}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{ p: 0, mx: 0 }} display={'flex'} minHeight={'800px'} maxHeight={'800px'}>
                        <Grid item xs={3} sx={{ borderRight: '1px solid', borderColor: theme.palette.grey[50] }} overflow={'auto'}>
                            <Paper elevation={0} sx={{ backgroundColor: theme.palette.background.paper }}>
                                {jobsListElem}
                            </Paper>
                        </Grid>
                        <Grid item xs={9} display={'flex'} justifyContent={'space-between'} overflow={'auto'}>
                            {jobsDetailsElem}
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Grid>

        </Grid>
    );
};

export default JobsScreen;
