import React from 'react';
import PropTypes from 'prop-types';

import { usePlacesWidget } from 'react-google-autocomplete';

import TextInput from './TextInput';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_googleMapsKey;

function AddressInput({
    id,
    onChange,
    onSelect,
    label,
    value,
    helperText,
    disabled,
    errorMsg,
}) {
    const { ref } = usePlacesWidget({
        apiKey: GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => onSelect(place),
        options: {
            // fields: ['address_components', 'geometry', 'formatted_address'],
            componentRestrictions: { country: ['us', 'ca'] },
            types: ['address'],
        },
    });

    return (
        <TextInput
            id={id}
            onChange= {onChange}
            label={label}
            value={value}
            helperText={helperText}
            inputRef={ref}
            disabled={disabled}
            errorMsg={errorMsg}
        />
    );
}

AddressInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
};

export default AddressInput;