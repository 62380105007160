
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useError } from 'contexts/ErrorContext';
import { useLocations, actions, TYPES as LOCATION_TYPES } from 'services/location';
import { getValidationMessage } from 'services/utils/validation';

import Modal from 'components/Modal';

import LocationForm from './LocationForm';

import './styles.css';

const LocationModal = ({ open, onClose })=> {
    const err = useError();
    const locationContext = useLocations();
    const newLocation = locationContext.state.newLocation;

    const handlePropChange = (propName, propValue) => {
        locationContext.DISPATCH({
            type: LOCATION_TYPES.EDIT_NEW_LOCATION_PROP,
            payload: { propName, propValue },
        });
    };

    const handleContactChange = (propName, propValue) => {
        locationContext.DISPATCH({
            type: LOCATION_TYPES.EDIT_NEW_LOCATION_CONTACT_PROP,
            payload: { propName, propValue },
        });
    };

    const handleAddressChange = (place) => {
        locationContext.DISPATCH({
            type: LOCATION_TYPES.EDIT_NEW_LOCATION_ADDRESS,
            payload: place,
        });
    };

    const handleCreateLocation = () => {
        onClose();
        actions.createLocation(newLocation, locationContext, err);
    };

    useEffect(() => {
        actions.validateNewLocation(locationContext);
    }, [newLocation]);

    const errorMessage = getValidationMessage(locationContext?.state?.newLocationValidations || {});

    return (
        <Modal
            open={open}
            title='Add New Location'
            onClose={onClose}
            onSubmit={handleCreateLocation}
            showCloseButton
            showSubmitButton
            submitButtonDisabled={Object.values(locationContext?.state?.newLocationValidations || {}).length > 0}
            submitButtonTooltip={errorMessage}
        >
            <LocationForm
                location={newLocation}
                handlePropChange={handlePropChange}
                handleContactChange={handleContactChange}
                handleAddressChange={handleAddressChange}
                validations={locationContext?.state?.newLocationValidations || {}}
            />
        </Modal>
    );
};

LocationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

export default LocationModal;
