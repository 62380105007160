import { ROUTES } from 'services/utils/enums';
import { sendRequestToPortalAPI } from '../utils/api';

import { getCompany } from 'services/company/api';

export const getNotifications = async function() {
    let compId;

    const companyRes = await getCompany();

    if (companyRes?.status == 200) {
        compId = companyRes.data.payload.company_id;
    }
    else {
        throw new Error('Error while retrieving company data:', companyRes);
    }

    return sendRequestToPortalAPI(ROUTES.GET_NOTIFICATIONS, compId);
};

export const markNotificationAsRead = async function(id) {
    return sendRequestToPortalAPI(ROUTES.MARK_NOTIFICATION_AS_READ, id);
};

export default {
    getNotifications,
    markNotificationAsRead,
};
